<script setup>
import { ref, onMounted, onBeforeMount, watch } from "vue";
// import { i18n } from './main';
import axios from 'axios';
const API_BASE_URL = ref(process.env.VUE_APP_API_BASE_URL);
const categories = ref([]);
const isDarkMode = ref(false);
const langue = ref("ar");
const direction = ref("rtl");
const exist = ref(true);
const icon = ref('toggle-off');

// const switchLanguage = (locale) => {
//   langue.value = locale;
//   i18n.global.locale = langue.value;
//   updateDirection();
//   updateStyles();
//   fetchCategories();
//   localStorage.setItem("locale", langue.value);
// };

const updateDirection = () => {
  direction.value = langue.value === "ar" ? "rtl" : "ltr";
  document.querySelector("html").setAttribute('dir', direction.value);
  document.querySelector("html").setAttribute("lang", langue.value)
};

const updateStyles = () => {
  // import(`./assets/styles_${langue.value}.scss`);
  import("./assets/index.scss");
};

const fetchCategories = async () => {
  
  try {
    const response = await axios.get(`${API_BASE_URL.value}/categories`);
    categories.value = response.data;
  } catch (error) {
    console.error(error);
  }
};

const initialize = () => {
  if (localStorage.getItem("locale")) {
    langue.value = localStorage.getItem("locale");
  }
  if (localStorage.getItem("mySavedData")) {
    isDarkMode.value = localStorage.getItem("mySavedData") === "true";
  }
  updateDirection();
  updateStyles();
  fetchCategories();
};

const updateLayout = () => {
  exist.value = window.innerWidth >= 576;
};

const changeIcon = () => {
  icon.value = icon.value === "toggle-off" ? "toggle-on" : "toggle-off";
  isDarkMode.value = !isDarkMode.value;
  localStorage.setItem("mySavedData", isDarkMode.value);
};

watch(() => isDarkMode.value, (newValue) => {
  localStorage.setItem("mySavedData", newValue);
});

watch(() => langue.value, () => {
  updateDirection();
  fetchCategories();
});

onBeforeMount(updateLayout);
onMounted(initialize);

</script>

<template>
  <div :class="['roboto-light website_container', { 'dark-mode': isDarkMode, 'light-mode': !isDarkMode }]">
    <nav class="navbar navbar-expand-sm">
      <div class="container-fluid mx-3">
        <a class="navbar-brand" href="/">BeCurious</a>
        <div class="two_links">
        <button v-if="!exist" class="btn active border-0 small-icon" @click="changeIcon">
          <font-awesome-icon :icon="['fas', icon]" />
        </button>
        <button :class="['navbar-toggler', { 'dark-mode': isDarkMode, 'light-mode': !isDarkMode }]" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span><font-awesome-icon icon="fa-solid fa-chart-bar" /></span>
        </button>
        </div>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link active" href="/">{{ $t('home') }}</a>
            </li>
            <!-- <li v-if="exist" class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                switch language
              </a>
              <ul class="dropdown-menu p-0">
                <li>
                  <a class="text-decoration-none text-center dropdown-item" href="/home" @click="switchLanguage('en')">switch to english</a>
                  <a class="text-decoration-none text-center dropdown-item" href="/home" @click="switchLanguage('ar')">switch to arabic</a>
                </li>
              </ul>
            </li> -->
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{ $t('latest news') }}
              </a>
              <ul class="dropdown-menu p-0">
                <li v-for="categorie in categories" :key="categorie.category_id">
                  <router-link :to="{ name: 'categorie', params: { categorie: categorie.category_id } }" class="text-decoration-none text-center dropdown-item">{{ categorie.name }}</router-link>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/comingsoon">{{ $t('reviews') }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/comingsoon">{{ $t('contact us') }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/comingsoon">{{ $t('more') }}</a>
            </li>
            <li v-if="exist" class="nav-item">
              <button class="btn active border-0" @click="changeIcon">
                <font-awesome-icon :icon="['fas', icon]" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="content">
      <router-view :locale="langue" :isDarkMode="isDarkMode"></router-view>
    </div>
    <div class="footer w-100 py-2 px-3 text-center">
      <div>
        <a class="text-decoration-none mx-1 privacy" href="/PrivacyPolicy"> {{ $t('privacy_policy') }} </a>
        <span v-if="exist"><strong>|</strong></span>

        <!-- <span><strong>|</strong></span> -->
        <a class="text-decoration-none mx-1 privacy" href="/TermsOfUse"> {{ $t('terms_of_service') }} </a>
        <span v-if="exist"><strong>|</strong></span>


        <p class="mb-0 copyright me-2">{{ $t('all_rights_reserved') }} . BeCurious Journey &copy; 2024 </p>

      </div>
      <!-- <div v-if="!exist">
        <button class="btn btn-danger p-0" @click="switchLanguage('en')">switch to english</button>
        <button class="btn btn-danger mx-1 my-1 p-0" @click="switchLanguage('ar')">switch to arabic</button>
      </div> -->
    </div>
  </div>
</template>

<style>
/* Add your styles here */
</style>
