import 'bootstrap/dist/css/bootstrap.css';

import { createApp, defineAsyncComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'


import { createI18n } from 'vue-i18n';
import entranslations from './en.json'; 
import artranslations from './ar.json'; 
const i18n = createI18n({
  locale: 'ar', // Set the initial locale to arabic
  messages: {
    en: entranslations,
    ar: artranslations,
  },
});
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import specific icons */
import { faChartBar } from '@fortawesome/free-solid-svg-icons'
import { faToggleOn} from '@fortawesome/free-solid-svg-icons'
import { faToggleOff} from '@fortawesome/free-solid-svg-icons'
import { faAngleRight} from '@fortawesome/free-solid-svg-icons'
import { faAngleLeft} from '@fortawesome/free-solid-svg-icons'
/* add icons to the library */
library.add(faChartBar)
library.add(faToggleOn)
library.add(faToggleOff)
library.add(faAngleRight)
library.add(faAngleLeft)

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const NewsCards = defineAsyncComponent(() =>
import('./components/NewsCards.vue')
)
const NewPage = defineAsyncComponent(() =>
  import('./components/NewPage.vue')
)
const NewsCategories = defineAsyncComponent(()=>
  import('./components/NewsCategories.vue')
)
const ComingSoon = defineAsyncComponent(()=>
  import('./components/ComingSoon.vue')
)
const PrivacyPolicy = defineAsyncComponent(()=>
  import('./components/PrivacyPolicy.vue')
)
const TermsOfUse = defineAsyncComponent(()=>
  import('./components/TermsOfUse.vue')
)
const router = createRouter({
    history: createWebHistory(),
    routes: [
      { path: '', component: NewsCards, name:"home"},
      {path: '/explore/:categorie', component: NewsCategories, name:"categorie"}, 
        { path: '/explore/:categorie/:slug', component: NewPage, name:"new" /* , props: (route) => ({ dataFromHome: route.query.data }), */},
        {path: '/comingsoon', component: ComingSoon, name:"coming_soon"}, 
        {path: '/PrivacyPolicy', component: PrivacyPolicy, name:"privacy_policy"}, 
        {path: '/TermsOfUse', component: TermsOfUse, name:"terms_of_use"}, 

    ]
});
const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)

app.use(i18n)
app.use(router);
app.mount("#app")
export {i18n}


import 'bootstrap/dist/js/bootstrap.js';